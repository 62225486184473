import { Component, OnInit } from '@angular/core';
import { deliveryProvider_deliveryProvider } from 'src/app/core/services/apollo/deliveryProvider';
import { DeliveryRepository } from 'src/app/core/services/delivery.repository';

@Component({
  selector: 'app-pickup',
  templateUrl: './pickup.component.html',
  styleUrls: ['./pickup.component.scss'],
})
export class PickupComponent implements OnInit {
  pickupPoints: Location[] = [];

  constructor(private deliveryRepository: DeliveryRepository) {
    this.deliveryRepository
      .getPickupPoints()
      .subscribe((deliveryProvider: deliveryProvider_deliveryProvider) => {
        this.pickupPoints = deliveryProvider.configuration[0]
          ? JSON.parse(deliveryProvider.configuration[0]?.value)
          : [];
      });
  }

  updatePickupPoints(): void {
    this.deliveryRepository.updatePickupPoints(this.pickupPoints);
  }

  ngOnInit(): void {}
}
