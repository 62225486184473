import { Component } from '@angular/core';
import { AssortmentRepository } from 'src/app/core/services/assortment.repository';
import { Assortment } from 'src/app/declarations/assortment.interface';

@Component({
  selector: 'app-assortment',
  templateUrl: './assortment.component.html',
  styleUrls: ['./assortment.component.scss'],
})
export class AssortmentComponent {
  assortments: Assortment[] = [];

  constructor(private assortmentRepository: AssortmentRepository) {
    this.assortmentRepository.getAssortments().subscribe((assortments) => {
      this.assortments = [];
      assortments.map((assortment) =>
        this.assortments.push({
          ...assortment.texts,
          _id: assortment._id,
        })
      );
    });
  }

  setAssortment(event: any): void {
    delete event.data.__KEY__;
    console.log(event.data);
    this.assortmentRepository.setAssortment(event.data);
  }

  updateAssortment(event: any): void {
    delete event.newData.isActive;
    this.assortmentRepository.updateAssortmentTexts(
      event.oldData._id,
      event.newData
    );
  }

  removeAssortment(event: any): void {
    this.assortmentRepository.removeAssortment(event.data._id);
  }
}
