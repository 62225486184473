import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  GET_DELIVERY_PROVIDER,
  UPDATE_DELIVERY_PROVIDER,
} from './graphql.queries';
import { ApolloQueryResult } from '@apollo/client/core';
import { deliveryProvider_deliveryProvider } from './apollo/deliveryProvider';
import { DELIVERY_PROVIDER_ID } from 'src/app/declarations/constants';

@Injectable({
  providedIn: 'root',
})
export class DeliveryRepository {
  constructor(private apollo: Apollo) {}

  getPickupPoints(): Observable<deliveryProvider_deliveryProvider> {
    return this.apollo
      .watchQuery({
        query: GET_DELIVERY_PROVIDER,
        variables: {
          deliveryProviderId: DELIVERY_PROVIDER_ID,
        },
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) => result?.data.deliveryProvider)
      );
  }

  updatePickupPoints(configuration: any): Promise<ApolloQueryResult<any>> {
    const newConfiguration: any[] = [];
    configuration.map((conf: any) => {
      if (!conf._id) {
        conf._id = conf.__KEY__;
        delete conf.__KEY__;
      }
      newConfiguration.push(conf);
    });
    return this.apollo
      .mutate({
        mutation: UPDATE_DELIVERY_PROVIDER,
        variables: {
          deliveryProviderId: DELIVERY_PROVIDER_ID,
          deliveryProvider: {
            configuration: [
              {
                key: 'stores',
                value: JSON.stringify(newConfiguration),
              },
            ],
          },
        },
        refetchQueries: [
          {
            query: GET_DELIVERY_PROVIDER,
            variables: { deliveryProviderId: DELIVERY_PROVIDER_ID },
          },
        ],
      })
      .pipe(
        take(1),
        map((result: any) => result)
      )
      .toPromise();
  }
}
