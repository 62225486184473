import { NavItem } from '../declarations/nav-item';

export let menu: NavItem[] = [
  { displayName: 'Home', iconName: 'home', route: 'home' },
  { displayName: 'Orders', iconName: 'receipt', route: 'orders' },
  // { displayName: 'Weekly Menu', iconName: 'menu_book', route: 'weeklymenu' },
  {
    displayName: 'Products',
    iconName: 'fastfood',
    route: 'products',
  },
  { displayName: 'Assortments', iconName: 'category', route: 'assortment' },
  { displayName: 'Pickup', iconName: 'all_inbox', route: 'pickup' },
  // {
  //   displayName: 'Settings',
  //   iconName: 'settings',
  //   route: 'settings',
  // },
  {
    displayName: 'User',
    iconName: 'face',
    route: 'user',
  },
];
