import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FileInput } from 'ngx-material-file-input';
import { AssortmentRepository } from 'src/app/core/services/assortment.repository';
import { ProductRepository } from 'src/app/core/services/product.repository';
import { ActivatedRoute, Router } from '@angular/router';
import { Products_products_SimpleProduct } from 'src/app/core/services/apollo/Products';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Assortments_assortments } from 'src/app/core/services/apollo/Assortments';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/shared/dialogs/confirmation/confirmation.component';
import { CreateProductInput } from 'src/app/core/services/apollo/globalTypes';
import { productTexts_translatedProductTexts } from 'src/app/core/services/apollo/productTexts';

export interface FormItems {
  controlName: string;
  label: string;
}

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss'],
})
export class AddProductComponent implements OnInit {
  form!: FormGroup;
  components: FormItems[] = [];
  languages = [
    { key: 'de', label: 'Deutsch' },
    { key: 'en', label: 'Englisch' },
  ];
  files: any;
  product: Products_products_SimpleProduct;
  imageObjectUrl: SafeResourceUrl;
  assortments: Assortments_assortments[];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private assortmentRepository: AssortmentRepository,
    private productRepository: ProductRepository,
    private sanitize: DomSanitizer,
    public dialog: MatDialog
  ) {
    this.form = this.fb.group({
      de: this.createLanguageForm(),
      en: this.createLanguageForm(),
      pickup: [false],
      delivery: [false],
      assortment: [''],
      file: [''],
      price: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$'),
        ]),
      ],
    });

    this.form.valueChanges.subscribe((value) => {
      if (value.file) {
        this.imageObjectUrl = this.sanitize.bypassSecurityTrustResourceUrl(
          URL.createObjectURL(value.file.files[0])
        );
      }
    });

    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.productRepository
        .getProduct(id)
        .subscribe((product: Products_products_SimpleProduct) => {
          this.product = product;
          this.form.patchValue({
            assortment: product.assortmentPaths[0]?.links[0]?.assortmentId,
            price: product.catalogPrice?.amount / 100,
          });
        });
      this.productRepository
        .getProductTexts(id)
        .subscribe((texts: productTexts_translatedProductTexts[]) => {
          texts.forEach((text) => {
            this.form.patchValue({
              [text.locale]: {
                title: text.title,
                subtitle: text.subtitle,
                description: text.description,
                ingredients: text.labels?.join(','),
              },
            });
          });
        });
    }

    this.assortmentRepository
      .getAssortments()
      .subscribe((assortments: Assortments_assortments[]) => {
        this.assortments = assortments;
        const starters = assortments.find((assortment) => {
          return assortment.texts.title === 'Starters';
        });

        starters.productAssignments.forEach((link: any, index: number) => {
          this.form.addControl(
            `components${link.product._id}`,
            new FormControl({ value: false })
          );
          this.components.push({
            controlName: `components${link.product._id}`,
            label: link.product.texts.title,
          });
        });
      });
  }

  ngOnInit(): void {}

  onFileChanged(event: any): void {
    this.files = event.target.files;
  }

  createLanguageForm(): FormGroup {
    return this.fb.group({
      title: ['', Validators.compose([Validators.required])],
      subtitle: [''],
      description: [''],
      ingredients: [''],
    });
  }

  updateProductFields(prod: any): void {
    if (this.form.value.file) {
      const file = (this.form.value.file as FileInput).files[0];
      this.productRepository.addProductMedia(prod._id, file);
    }

    this.languages.forEach((lang) => {
      this.productRepository.updateProductTexts(prod._id, {
        title: this.form.value[lang.key].title,
        subtitle: this.form.value[lang.key].subtitle,
        description: this.form.value[lang.key].description,
        labels: this.form.value[lang.key].ingredients?.trim().split(',') || [],
        locale: lang.key,
      });
    });

    if (this.form.value.assortment) {
      this.assortmentRepository.addProductAssortment(
        this.form.value.assortment,
        prod._id
      );
    }

    this.productRepository.updateProductCommerce(prod._id, {
      amount: this.form.value.price.toString().includes('.')
        ? Number(this.form.value.price.toString().replace(/\./g, ''))
        : Number(this.form.value.price.toString() + '00'),
      isTaxable: true,
      currencyCode: 'CHF',
      countryCode: 'CH',
    });
    this.router.navigateByUrl('/products');
  }

  onSubmit(): void {
    if (this.product) {
      this.updateProductFields(this.product);
      return;
    }
    const product: CreateProductInput = {
      title: this.form.value.de.title,
      type: 'SimpleProduct',
    };

    this.productRepository.setProduct(product).subscribe((prod) => {
      this.updateProductFields(prod);
    });
  }

  publish(): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      maxWidth: '400px',
      data: {
        title: 'Veröffentlichen?',
        message:
          'Sind sie sicher dass die das Produkt für Kunden zum Verkauf bereitstellen möchten?',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.productRepository.publishProduct(this.product._id);
      }
    });
  }

  unpublish(): void {
    this.productRepository.unpublishProduct(this.product._id);
  }
}
