<div class="container">
  <h1>Pickup Punkte</h1>
  <dx-data-grid
    id="gridContainer"
    class="mt-4"
    [height]="'80%'"
    [dataSource]="pickupPoints"
    [remoteOperations]="false"
    [allowColumnReordering]="true"
    [rowAlternationEnabled]="true"
    [showBorders]="true"
    (onRowUpdated)="updatePickupPoints()"
    (onRowRemoved)="updatePickupPoints()"
    (onRowInserted)="updatePickupPoints()"
  >
    <dxo-scrolling mode="standard" useNative="true"></dxo-scrolling>
    <dxo-paging [enabled]="true"></dxo-paging>
    <dxo-editing
      mode="row"
      [allowUpdating]="true"
      [allowDeleting]="true"
      [allowAdding]="true"
    >
    </dxo-editing>

    <dxi-column caption="Name" dataField="name" sortOrder="asc"></dxi-column>
    <dxi-column caption="Adresse" dataField="address.addressLine"></dxi-column>
    <dxi-column
      caption="Adress Zusatz"
      dataField="address.addressLine2"
    ></dxi-column>
    <dxi-column
      dataField="isActive"
      caption="Status"
      dataType="boolean"
      width="150"
    ></dxi-column>
  </dx-data-grid>
</div>
