<div fxFlex fxLayout="column" fxLayoutGap="0px">
  <div fxLayout="row wrap">
    <div fxFlex="100" fxLayout="column" class="background--primary">
      <div fxLayout="column" fxLayoutAlign="center" style="margin-top: 30px">
        <div fxLayout="row" fxLayoutAlign="center">
          <h1>Welcome to the Uptown Restaurant Manager</h1>
        </div>
      </div>

      <div fxLayout="column" fxLayoutAlign="center" style="margin-top: 100px">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div fxLayoutAlign="center">
            <mat-form-field style="width: 50%" appearance="fill">
              <mat-label>Enter email</mat-label>
              <input formControlName="email" matInput />
              <mat-error *ngIf="form.controls['email'].invalid"
                >Please enter a valid email</mat-error
              >
            </mat-form-field>
          </div>
          <div fxLayoutAlign="center" style="margin-top: 10px">
            <mat-form-field style="width: 50%" appearance="fill">
              <mat-label>Enter password</mat-label>
              <input type="password" formControlName="password" matInput />
              <mat-error *ngIf="form.controls['password'].invalid"
                >Please enter a valid password</mat-error
              >
            </mat-form-field>
          </div>
          <div fxLayoutAlign="center" style="margin-top: 20px">
            <button
              [disabled]="!form.valid || formSubmitted"
              type="submit"
              mat-raised-button
              color="primary"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
