import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AngularTokenService } from 'angular-token';
import { BehaviorSubject } from 'rxjs';
import { NavItem } from 'src/app/declarations/nav-item';
import { User } from 'src/app/declarations/user.interface';
import { menu } from 'src/app/model/menu';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  public opened = true;
  public menu: NavItem[] = menu;
  public user$: BehaviorSubject<User>;
  sidenavWidth = 4;

  constructor(
    private authenticationService: AngularTokenService,
    private router: Router
  ) {
    this.user$ = this.authenticationService.userData;
  }

  logout(): void {
    this.authenticationService.signOut().subscribe(
      (res) => this.router.navigateByUrl('/login'),
      (error) => this.router.navigateByUrl('/login')
    );
  }

  increase(): void {
    this.sidenavWidth = 15;
  }
  decrease(): void {
    this.sidenavWidth = 4;
  }
}
