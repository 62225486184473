<div class="container">
  <h1>Produkte</h1>
  <div fxLayout="row" class="row" fxLayoutAlign="space-between">
    <button mat-raised-button color="primary" routerLink="add">
      Add Product
    </button>

    <button mat-raised-button color="secondary" (click)="clearFilter()">
      Clear filter
    </button>
  </div>
  <dx-data-grid
    id="gridContainer"
    class="mt-4"
    [height]="'80%'"
    [dataSource]="products"
    [remoteOperations]="false"
    [allowColumnReordering]="true"
    [rowAlternationEnabled]="true"
    [showBorders]="true"
  >
    <dxo-scrolling mode="standard" useNative="true"></dxo-scrolling>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxi-column
      dataField="media[0].file.url"
      caption="Bild"
      width="150"
      [allowFiltering]="false"
      [allowSorting]="false"
      cellTemplate="cellTemplateImage"
    ></dxi-column>
    <dxi-column
      caption="Titel"
      dataField="texts.title"
      dataType="string"
    ></dxi-column>
    <dxi-column
      caption="Preis"
      dataField="catalogPrice.amount"
      dataType="number"
      cellTemplate="cellTemplatePrice"
    ></dxi-column>
    <dxi-column
      caption="Kategorie"
      dataField="assortmentPaths[0].links[0].assortmentTexts.title"
      [allowFiltering]="false"
      [allowHeaderFiltering]="true"
    ></dxi-column>
    <dxi-column
      caption="Veröffentlicht am"
      dataField="published"
      cellTemplate="cellTemplateDate"
      sortOrder="desc"
      width="150"
      dataType="date"
    ></dxi-column>
    <dxi-column
      caption="Status"
      dataField="status"
      [allowFiltering]="false"
      [allowHeaderFiltering]="true"
    ></dxi-column>
    <dxi-column
      caption="Aktionen"
      dataField="_id"
      cellTemplate="cellTemplateActions"
      [allowFiltering]="false"
    ></dxi-column>

    <div *dxTemplate="let data of 'cellTemplateImage'">
      <img class="thumbnail" [src]="data.value" />
    </div>
    <div *dxTemplate="let data of 'cellTemplatePrice'">
      <span>{{ data.value | price }}</span>
    </div>
    <div *dxTemplate="let data of 'cellTemplateDate'">
      <span>{{ data.value | date: "dd.MM.YY mm:HH" }}</span>
    </div>
    <div *dxTemplate="let data of 'cellTemplateActions'">
      <button
        mat-icon-button
        color="primary"
        *ngIf="data.row.data.status === 'DRAFT'"
        (click)="publish(data.value)"
      >
        <mat-icon>published_with_changes</mat-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        *ngIf="data.row.data.status === 'ACTIVE'"
        (click)="unpublish(data.value)"
      >
        <mat-icon>unpublished</mat-icon>
      </button>
      <button mat-icon-button color="primary" (click)="delete(data.value)">
        <mat-icon>delete</mat-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        [routerLink]="['edit', data.value]"
      >
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </dx-data-grid>
</div>
