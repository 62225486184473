import { Component } from '@angular/core';
import { AngularTokenService } from 'angular-token';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'uptown-unchained-admin';

  constructor(private tokenService: AngularTokenService) {
    // this is called so after page refresh we get the userdata again
    this.tokenService.validateToken().subscribe();
  }
}
