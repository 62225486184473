import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductRepository } from 'src/app/core/services/product.repository';
import { Products_products } from 'src/app/core/services/apollo/Products';
import { ConfirmationComponent } from 'src/app/shared/dialogs/confirmation/confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  products: Products_products[];

  constructor(
    private productRepository: ProductRepository,
    public dialog: MatDialog
  ) {
    this.productRepository.getProducts().subscribe((products) => {
      this.products = products;
    });
  }

  ngOnInit(): void {}

  publish(productId: string): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      maxWidth: '400px',
      data: {
        title: 'Veröffentlichen?',
        message:
          'Sind sie sicher dass die das Produkt für Kunden zum Verkauf bereitstellen möchten?',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.productRepository.publishProduct(productId);
      }
    });
  }

  unpublish(productId: string): void {
    this.productRepository.unpublishProduct(productId);
  }

  delete(productId: string): void {
    this.productRepository.removeProduct(productId);
  }

  clearFilter(): void {
    this.dataGrid.instance.clearFilter('row');
    this.dataGrid.instance.clearFilter('header');
  }
}
