import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GET_ORDER,
  GET_ORDERS,
  REMOVE_ORDER,
} from '../../core/services/graphql.queries';
import { ApolloQueryResult } from '@apollo/client/core';
import { Order } from './apollo/Order';

@Injectable({
  providedIn: 'root',
})
export class OrderRepository {
  constructor(private apollo: Apollo) {}

  getOrders(): Observable<Order[]> {
    return this.apollo
      .watchQuery({
        query: GET_ORDERS,
        variables: { limit: 100, includeCarts: true },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) => result?.data.orders)
      );
  }

  removeOrder(orderId: string): Promise<any> {
    return this.apollo
      .mutate({
        mutation: REMOVE_ORDER,
        variables: {
          orderId,
        },
        refetchQueries: [{ query: GET_ORDERS }],
      })
      .toPromise();
  }

  findOne(orderId: string): Observable<Order> {
    return this.apollo
      .watchQuery({
        query: GET_ORDER,
        variables: { orderId },
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) => result?.data.order)
      );
  }
}
