<!-- <mat-accordion> -->
<div class="container">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Opening Hours</mat-panel-title>
      <mat-panel-description> Change your Opening hours </mat-panel-description>
    </mat-expansion-panel-header>
    <p>Feature not yet available</p>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Delivery Slots </mat-panel-title>
      <mat-panel-description
        >Set your delivery slots when your food will be
        delivered</mat-panel-description
      >
    </mat-expansion-panel-header>
    <p>Not yet implemented</p>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Delivery + Pick-Up Points </mat-panel-title>
      <mat-panel-description>
        Define buildings and rooms for Pickup
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p>Not yet implemented</p>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Team </mat-panel-title>
      <mat-panel-description
        >Give your Team members access to this Admin Panel and your
        menu</mat-panel-description
      >
    </mat-expansion-panel-header>
    <p>I'm visible because I am open</p>
  </mat-expansion-panel>
  <!-- </mat-accordion> -->
</div>
