import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import { Order } from 'src/app/core/services/apollo/Order';
import { OrderRepository } from 'src/app/core/services/order.repository';
import { DATE_FORMAT } from 'src/app/declarations/constants';
import { OrdersService } from './orders.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  orders: Order[];

  dateFormat = DATE_FORMAT;

  constructor(
    private orderRepository: OrderRepository,
    private ordersService: OrdersService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.orderRepository.getOrders().subscribe((orders) => {
      this.orders = orders;
    });
  }

  cancelOrder(orderId: string): void {
    this.ordersService.cancelOrder(orderId);
  }

  getOrderedItemLabel(
    title: string,
    quantity: number,
    isLast: boolean
  ): string {
    return `${title} x${quantity}${!isLast ? ', ' : ''}`;
  }

  clearFilter(): void {
    this.dataGrid.instance.clearFilter('row');
    this.dataGrid.instance.clearFilter('header');
  }

  openDetail(order: Order): void {
    this.router.navigateByUrl(`/orders/${order._id}`);
  }
}
