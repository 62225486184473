import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price',
})
export class PricePipe implements PipeTransform {
  constructor(private currency: CurrencyPipe) {}

  transform(value: number, defaultValue = '0.00'): string {
    if (!value) {
      return defaultValue;
    }
    return this.currency.transform(value / 100, 'CHF ');
  }
}
