<div class="container">
  <h1>Aufträge</h1>
  <div fxLayout="row" class="row" fxLayoutAlign="end">
    <button mat-raised-button color="secondary" (click)="clearFilter()">
      Clear filter
    </button>
  </div>
  <dx-data-grid
    id="gridContainer"
    class="mt-4"
    [height]="'80%'"
    [dataSource]="orders"
    [remoteOperations]="false"
    [allowColumnReordering]="true"
    [rowAlternationEnabled]="true"
    [showBorders]="true"
  >
    <dxo-scrolling mode="standard" useNative="true"></dxo-scrolling>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxi-column
      caption="Date Ordered"
      dataField="ordered"
      dataType="date"
      cellTemplate="cellTemplateDate"
      sortOrder="desc"
      width="150"
    ></dxi-column>
    <dxi-column
      caption="Items"
      dataField="items"
      cellTemplate="cellTemplateItems"
      [allowFiltering]="false"
    ></dxi-column>
    <dxi-column
      dataField="orderNumber"
      caption="Order Number"
      dataType="string"
      [allowHeaderFiltering]="false"
      cellTemplate="cellTemplateLink"
    ></dxi-column>
    <dxi-column
      dataField="status"
      caption="Status"
      dataType="string"
      [allowFiltering]="false"
      [allowHeaderFiltering]="true"
    ></dxi-column>
    <dxi-column
      dataField="user.username"
      caption="Customer"
      dataType="string"
      [allowHeaderFiltering]="false"
    ></dxi-column>
    <dxi-column
      caption="Preis"
      dataField="total.amount"
      dataType="number"
      cellTemplate="cellTemplatePrice"
      width="120"
      [allowHeaderFiltering]="false"
    ></dxi-column>
    <dxi-column
      caption="Aktionen"
      dataField="_id"
      cellTemplate="cellTemplateActions"
      [allowFiltering]="false"
      width="70"
    ></dxi-column>

    <div *dxTemplate="let data of 'cellTemplateLink'">
      <a class="link" (click)="openDetail(data.data)">{{ data.value }}</a>
    </div>
    <div *dxTemplate="let data of 'cellTemplatePrice'">
      <span>{{ data.value | price }}</span>
    </div>
    <div *dxTemplate="let data of 'cellTemplateDate'">
      <span>{{ data.value | date: dateFormat }}</span>
    </div>
    <div *dxTemplate="let data of 'cellTemplateItems'">
      <span *ngFor="let item of data.value; let last = last">
        {{ getOrderedItemLabel(item.product.texts.title, item.quantity, last) }}
      </span>
    </div>
    <div *dxTemplate="let data of 'cellTemplateActions'">
      <button
        *ngIf="data.row.data.status === 'OPEN'"
        class="cancel"
        mat-icon-button
        color="primary"
        (click)="cancelOrder(data.value)"
      >
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </dx-data-grid>
</div>
