<a
  mat-list-item
  [ngStyle]="{ 'padding-left': depth * 12 + 'px' }"
  (click)="onItemSelected(item)"
  [ngClass]="{
    active: item.route ? router.isActive(item.route, true) : false,
    expanded: expanded
  }"
  class="menu-list-item"
>
  <mat-icon class="routeIcon">{{ item.iconName }}</mat-icon>
  <div *ngIf="!collapsed">
    <span class="menu-item-text">{{ item.displayName }}</span>
    <span fxflex class="twistie-separator"></span>
    <span *ngIf="item.children && item.children.length">
      <mat-icon
        class="menu-twistie"
        [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'"
      >
        expand_more
      </mat-icon>
    </span>
  </div>
</a>
<div *ngIf="expanded">
  <app-menu-list-item
    *ngFor="let child of item.children"
    [item]="child"
    [depth]="depth + 1"
  ></app-menu-list-item>
</div>
