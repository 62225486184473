import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrderRepository } from 'src/app/core/services/order.repository';
import { ConfirmationComponent } from 'src/app/shared/dialogs/confirmation/confirmation.component';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(
    private orderRepository: OrderRepository,
    private dialog: MatDialog
  ) {}

  cancelOrder(orderId: string): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      maxWidth: '400px',
      data: {
        title: 'Löschen?',
        message: 'Sind sie sicher dass sie diesen Auftrag löschen möchten?',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.orderRepository.removeOrder(orderId);
      }
    });
  }
}
