<div class="container">
  <h1>Menu-Kategorien</h1>
  <dx-data-grid
    id="gridContainer"
    class="mt-4"
    [height]="'80%'"
    [dataSource]="assortments"
    [rowAlternationEnabled]="true"
    [remoteOperations]="false"
    [showBorders]="true"
    (onRowUpdating)="updateAssortment($event)"
    (onRowRemoved)="removeAssortment($event)"
    (onRowInserted)="setAssortment($event)"
  >
    <dxo-scrolling mode="standard" useNative="true"></dxo-scrolling>
    <dxo-paging [enabled]="true"></dxo-paging>
    <dxo-editing
      mode="row"
      [allowUpdating]="true"
      [allowDeleting]="true"
      [allowAdding]="true"
    >
    </dxo-editing>

    <dxi-column caption="Titel" dataField="title" sortOrder="asc"></dxi-column>
    <dxi-column
      caption="Subtitel"
      dataField="subtitle"
      sortOrder="asc"
    ></dxi-column>
    <dxi-column caption="Beschreibung" dataField="description"></dxi-column>
    <dxi-column
      dataField="isActive"
      caption="Status"
      dataType="boolean"
      width="150"
    ></dxi-column>
  </dx-data-grid>
</div>
