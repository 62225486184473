import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularTokenService, SignInData } from 'angular-token';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form!: FormGroup;
  formSubmitted = false;
  signInData: SignInData = {} as SignInData;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private tokenService: AngularTokenService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])],
    });
  }

  async onSubmit(): Promise<void> {
    this.formSubmitted = true;

    if (this.form.valid) {
      this.signInData.login = this.form.controls.email.value;
      this.signInData.password = this.form.controls.password.value;

      this.tokenService.signIn(this.signInData).subscribe(
        (res) => this.router.navigateByUrl('/home'),
        (error) => {}
      );
    } else {
      console.log('The form is NOT valid');
      this.formSubmitted = false;
    }
  }
}
