import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './shared/material.module';
import { SettingsModule } from './pages/settings/settings.module';
import { LoginModule } from './pages/login/login.module';
import { LayoutModule } from './core/layout/layout.module';
import { ComponentsModule } from './shared/components/components.module';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { RedirectIfLoggedIn } from './shared/guards/redirect.guard';
import { HomeModule } from './pages/home/home.module';
import { AssortmentModule } from './pages/assortment/assortment.module';
import { ProductsModule } from './pages/products/products.module';
import { DxDataGridModule } from 'devextreme-angular';
import { PipesModule } from './shared/pipes/pipes.module';
import { AngularTokenModule } from 'angular-token';
import { environment } from 'src/environments/environment';
import { OrdersModule } from './pages/orders/orders.module';
import { PickupModule } from './pages/pickup/pickup.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    LoginModule,
    LayoutModule,
    SettingsModule,
    HomeModule,
    ComponentsModule,
    AssortmentModule,
    ProductsModule,
    PickupModule,
    GraphQLModule,
    DxDataGridModule,
    PipesModule,
    HttpClientModule,
    AngularTokenModule.forRoot({
      apiBase: environment.apiDomain,
    }),
    OrdersModule,
  ],
  providers: [RedirectIfLoggedIn],
  bootstrap: [AppComponent],
})
export class AppModule {}
