<mat-toolbar
  color="primary"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <div fxFlex="50">
    <span>Restaurant Admin Application</span>
  </div>
  <div fxFlex="50" fxLayoutAlign="end" fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutGap="10px" *ngIf="user$ | async as currentUser">
      <small>{{ currentUser?.email }}</small>
      <div class="avatar">
        <img
          [src]="
            currentUser?.avatar?.url ||
            '/assets/graphics/profile_placeholder.svg'
          "
        />
      </div>
    </div>
    <button mat-raised-button (click)="logout()">logout</button>
  </div>
</mat-toolbar>
<mat-sidenav-container [hasBackdrop]="false" autosize>
  <mat-sidenav
    #sidenav
    mode="push"
    [disableClose]="true"
    [opened]="true"
    [ngStyle]="{ 'width.em': sidenavWidth }"
  >
    <mat-nav-list>
      <app-menu-list-item
        *ngFor="let item of menu"
        [collapsed]="sidenavWidth === 4"
        [item]="item"
      ></app-menu-list-item>
    </mat-nav-list>
    <button
      mat-icon-button
      *ngIf="sidenavWidth === 4"
      (click)="sidenavWidth = 15"
      class="menu-button"
    >
      <mat-icon>navigate_next</mat-icon>
    </button>
    <button
      *ngIf="sidenavWidth === 15"
      mat-icon-button
      (click)="sidenavWidth = 4"
      class="menu-button"
    >
      <mat-icon>navigate_before</mat-icon>
    </button>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
