import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/material.module';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { AssortmentComponent } from './assortment.component';
import { DxDataGridModule } from 'devextreme-angular';

export const routes = [{ path: '', component: AssortmentComponent }];

@NgModule({
  declarations: [AssortmentComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    ReactiveFormsModule,
    DxDataGridModule,
    ComponentsModule,
    RouterModule.forChild(routes),
  ],
})
export class AssortmentModule {}
