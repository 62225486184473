import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersComponent } from './orders.component';
import { MaterialModule } from 'src/app/shared/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DxDataGridModule } from 'devextreme-angular';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { OrderDetailComponent } from './order-detail/order-detail.component';

export const routes = [
  { path: '', component: OrdersComponent },
  { path: ':id', component: OrderDetailComponent },
];

@NgModule({
  declarations: [OrdersComponent, OrderDetailComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    ReactiveFormsModule,
    DxDataGridModule,
    PipesModule,
    RouterModule.forChild(routes),
  ],
})
export class OrdersModule {}
