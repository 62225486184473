import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  ADD_ASSORTMENT,
  ADD_ASSORTMENT_PRODUCT,
  GET_ASSORTMENTS,
  REMOVE_ASSORTMENT,
  UPDATE_ASSORTMENT_TEXTS,
} from './graphql.queries';
import { ApolloQueryResult } from '@apollo/client/core';
import { Assortments_assortments } from './apollo/Assortments';
import { Assortment_texts } from './apollo/Assortment';

@Injectable({
  providedIn: 'root',
})
export class AssortmentRepository {
  constructor(private apollo: Apollo) {}

  getAssortments(): Observable<Assortments_assortments[]> {
    return this.apollo
      .watchQuery({
        query: GET_ASSORTMENTS,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) => result?.data.assortments)
      );
  }

  async setAssortment(
    texts: Assortment_texts
  ): Promise<ApolloQueryResult<any>> {
    const id = await this.apollo
      .mutate({
        mutation: ADD_ASSORTMENT,
        variables: {
          assortment: { title: texts.title, isRoot: true },
        },
      })
      .pipe(
        take(1),
        map((result: any) => result.data.createAssortment._id)
      )
      .toPromise();
    return this.updateAssortmentTexts(id, texts);
  }

  updateAssortmentTexts(
    assortmentId: string,
    texts: Assortment_texts
  ): Promise<ApolloQueryResult<any>> {
    return this.apollo
      .mutate({
        mutation: UPDATE_ASSORTMENT_TEXTS,
        variables: {
          assortmentId,
          texts: { ...texts, locale: 'en' },
        },
        refetchQueries: [{ query: GET_ASSORTMENTS }],
      })
      .pipe(
        take(1),
        map((result: any) => result)
      )
      .toPromise();
  }

  removeAssortment(assortmentId: string): Promise<ApolloQueryResult<any>> {
    return this.apollo
      .mutate({
        mutation: REMOVE_ASSORTMENT,
        variables: {
          assortmentId,
        },
        refetchQueries: [{ query: GET_ASSORTMENTS }],
      })
      .pipe(
        take(1),
        map((result: any) => result)
      )
      .toPromise();
  }

  addProductAssortment(
    assortmentId: string,
    productId: string
  ): Promise<ApolloQueryResult<any>> {
    return this.apollo
      .mutate({
        mutation: ADD_ASSORTMENT_PRODUCT,
        variables: {
          assortmentId,
          productId,
        },
      })
      .pipe(
        take(1),
        map((result: any) => result)
      )
      .toPromise();
  }
}
